<template>
    <div id="app">
      
      <productCreate/>
      
    </div>
  </template>
  
  <script>
  
  
  
  import productCreate from '../components/productCreate.vue';
  
  
  
  
  
  
  export default {
    name: 'App',
    components: {
    
    productCreate
    
  },
  mounted() {
    window.scrollTo(0, 0);
  }
  }
  </script>
  
  <style>
  
  </style>