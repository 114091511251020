<template>
  <div>
    <div v-if="variante">
      <h2>Variante Details</h2>
      <p>Farbe: {{ variante.farbe }}</p>
      <p>Akku: {{ variante.akku }}</p>
      <p>Speicher: {{ variante.speicher }}</p>
      <p>Größe: {{ variante.groeße }}</p>
      <p>Einzelhandelspreis: {{ variante.ezpreis }}€</p>
      <div class="button-container">
        <button @click="zurueck">Zurück</button>
        
        <button @click="bearbeiten">Bearbeiten</button>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        variante: null,
      };
    },
    mounted() {
      this.ladeVarianteDetails();
    },
    methods: {
      ladeVarianteDetails() {
        const pnr = this.$route.params.pnr;
        const vnr = this.$route.params.vnr;
        fetch(`https://168.119.224.190:444/api/variante/zeigevariante/${pnr}/${vnr}`)
          .then((response) => response.json())
          .then((data) => {
            this.variante = data.data[0];
          })
          .catch((error) => console.error('Fehler:', error));
      },
      zurueck() {
        this.$router.push({ name: 'VariantOverview' });
      },
      
      bearbeiten() {
        this.$router.push({ name: 'VariantEdit', params: { pnr: this.variante.produktnummer, vnr: this.variante.variantennummer } });
      },
    },
  };
  </script>
  
  <style scoped>
  .button-container {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    width: 100%;
    margin-top: 1rem; 
  }
  
  button {
    background-color: #222222;
    color: #fffdfd;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    padding: 0.4rem 0.8rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  
  div {
    padding: 0 0.625rem !important;
    flex: 0 0 auto;
    width: 60%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2% auto;
    gap: 1rem;
  }
  
  h2 {
    font-family: 'Open Sans', sans-serif !important;
    color: #1d1d1d;
  }
  
  p {
    font-family: 'Open Sans', sans-serif !important;
    color: #1d1d1d;
    padding: 0.5rem 0;
  }
  
  @media screen and (max-width: 800px) {
    div {
      width: 100%;
    }
  }
  </style>