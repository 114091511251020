<template>
  <h1>Kunde bearbeiten</h1>
  <div class="form-container">
    
  
    <form @submit.prevent="aktualisiereKunde" class="kunden-form">
      <div class="form-field">
        <label for="firmenbezeichnung">Firmenbezeichnung:</label>
        <input id="firmenbezeichnung" v-model="kunde.firmenbezeichnung" type="text">
      </div>

      <div class="form-field">
        <label for="kundennummer">Kundennummer:</label>
        <input id="kundennummer" v-model="kunde.kundennummer" type="text" disabled>
      </div>

      <div class="form-field">
        <label for="ort">Ort:</label>
        <input id="ort" v-model="kunde.ort" type="text">
      </div>

      <div class="form-field">
        <label for="plz">PLZ:</label>
        <input id="plz" v-model="kunde.plz" type="text">
      </div>

      <div class="form-field">
        <label for="hausnummer">Hausnummer:</label>
        <input id="hausnummer" v-model="kunde.hausnummer" type="text">
      </div>

      <div class="form-field">
        <label for="straße">Straße:</label>
        <input id="straße" v-model="kunde.straße" type="text">
      </div>

      <div class="form-field">
        <label for="kreditlimit">Kreditlimit:</label>
        <input id="kreditlimit" v-model="kunde.kreditlimit" type="number">
      </div>

      <div class="form-field">
        <label for="ustid">USt-IdNr.:</label>
        <input id="ustid" v-model="kunde.ustid" type="text">
      </div>

      <div class="form-field">
        <label for="zahlungsziel">Zahlungsziel:</label>
        <input id="zahlungsziel" v-model="kunde.zahlungsziel" type="text">
      </div>

      <div class="form-field">
        <label for="rabattsatz">Rabattsatz:</label>
        <input id="rabattsatz" v-model="kunde.rabattsatz" type="number">
      </div>

      <div class="form-field">
        <label for="mailadresse">E-Mail-Adresse:</label>
        <input id="mailadresse" v-model="kunde.mailadresse" type="email">
      </div>

      <div class="button-container">
        <button @click="abbruch" type="button" class="aktion-btn abbruch">Abbruch</button>
        <button type="submit" class="aktion-btn">Speichern</button>
      </div>
    </form>
  </div>
</template>

  
  <script>
  export default {
  data() {
    return {
      kunde: {
        kundennummer: this.$route.params.kdnr,
        firmenbezeichnung: '',
        ort: '',
        plz: '',
        hausnummer: '',
        straße: '',
        kreditlimit: 0,
        ustid: '',
        zahlungsziel: '',
        rabattsatz: 0,
        mailadresse: '',
      },
    };
  },
  mounted() {
    this.ladeKundeDetails();
  },
  methods: {
    ladeKundeDetails() {
      fetch(`https://168.119.224.190:444/api/kunden/zeigekunde/${this.kunde.kundennummer}`)
        .then(response => response.json())
        .then(data => {
          this.kunde = data.data[0]; 
        })
        .catch(error => console.error('Fehler:', error));
    },
    aktualisiereKunde() {
      const datenZumSenden = {
        plz: this.kunde.plz,
        hnr: this.kunde.hausnummer, 
        str: this.kunde.straße,
        fbez: this.kunde.firmenbezeichnung,
        krlim: parseFloat(this.kunde.kreditlimit), 
        ustid: this.kunde.ustid,
        zahlz: this.kunde.zahlungsziel,
        rasa: parseInt(this.kunde.rabattsatz, 10), 
        mail: this.kunde.mailadresse,
      };

      fetch(`https://168.119.224.190:444/api/kunden/bearbeiten/${this.kunde.kundennummer}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datenZumSenden),
      })
      .then(() => {
        this.$router.push({ name: 'CustomerDetail', params: { kdnr: this.kunde.kundennummer } });
      })
      .catch((error) => console.error('Fehler:', error));
    },
    abbruch() {
      this.$router.go(-1);
    },
  },
};

  </script>
  
  <style scoped>

  h1 {
    text-align: center;
  margin-bottom: 2rem;
  }
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.kunden-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 500px; 
}

.form-field {
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.1rem;
}

input[type="text"], input[type="number"], input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.aktion-btn {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.aktion-btn:hover {
  background-color: #000000;
  color: #fff;
}

.abbruch {
  background-color: #000000;
  color:#fff;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.aktion-btn[type="submit"] {
  background-color: rgb(0, 0, 0); 
  color: white; 
}


.aktion-btn[type="submit"]:hover {
  background-color: rgb(0, 0, 0);
}



@media screen and (max-width: 768px) {
  .kunden-form {
    width: 90%;
  }
}
</style>
  