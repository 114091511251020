<template>
  <div>
    <div v-if="kunde">
      <h2>Übersicht</h2>
      <p>Firmenbezeichnung: {{ kunde.firmenbezeichnung }}</p>
      <p>Kundennummer: {{ kunde.kundennummer }}</p>
      <p>PLZ: {{ kunde.plz }}</p>
      <p>Ort: {{ kunde.ort }}</p>
      <p>Straße: {{ kunde.straße }}</p>
      <p>Hausnummer: {{ kunde.hausnummer }}</p>
      <p>Kreditlimit: {{ kunde.kreditlimit }}</p>
      <p>USt-IdNr.: {{ kunde.ustid }}</p>
      <p>Zahlungsziel: {{ kunde.zahlungsziel }}</p>
      <p>Rabattsatz: {{ kunde.rabattsatz }}%</p>
      <p>E-Mail-Adresse: {{ kunde.mailadresse }}</p>
      <div class="button-container">
        <button @click="zurueck">Zurück</button>
        <button @click="loescheKunde(kunde.kundennummer)">Löschen</button>
        <button @click="bearbeiten">Bearbeiten</button>
      </div>
    </div>
    <div v-if="bestellpositionen.length">
      <h3>Bestellpositionen</h3>
      <div v-for="position in bestellpositionen" :key="position.bestellnummer" class="bestellposition-kachel">
        <p>Bestellnummer: {{ position.bestellnummer }}</p>
        <p>Produktnummer: {{ position.produktnummer }}</p>
        <p>Variantennummer: {{ position.variantennummer }}</p>
        <p>Menge: {{ position.menge }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      kunde: null,
      bestellpositionen: [],
    };
  },
  mounted() {
    this.ladeKundeDetails();
  },
  methods: {
    ladeKundeDetails() {
      const kdnr = this.$route.params.kdnr;
      fetch(`https://168.119.224.190:444/api/kunden/zeigekunde/${kdnr}`)
        .then((response) => response.json())
        .then((data) => {
          this.kunde = data.data[0];
          return fetch(`https://168.119.224.190:444/api/bestellung/zeigepositionenzukunde/${kdnr}`); 
        })
        .then((response) => response.json())
        .then((data) => {
          this.bestellpositionen = data.data; 
        })
        .catch((error) => console.error('Fehler:', error));
    },
    zurueck() {
      this.$router.push({ name: 'CustomerAll' });
    },
    loescheKunde(kdnr) {
      if (this.bestellpositionen.length > 0) {
      alert('Löschen nicht möglich: Es existieren zugeordnete Bestellpositionen.');
      return; 
    }
      fetch(`https://168.119.224.190:444/api/kunden/loeschen/${kdnr}`, { method: 'DELETE' })
        .then(() => {
          this.zurueck();
        })
        .catch((error) => console.error('Fehler:', error));
    },
    bearbeiten() {
      this.$router.push({ name: 'CustomerEdit', params: { kdnr: this.kunde.kundennummer } });
    },
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
  margin-top: 1rem; 
}

button {
  background-color: #222222;
  color: #fffdfd;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #0056b3;
}

.bestellposition-kachel {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9; 
}

div {
  padding: 0 0.625rem;
  flex: 0 0 auto;
  width: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% auto;
  gap: 1rem;
}

h2, h3 {
  font-family: 'Open Sans', sans-serif;
  color: #1d1d1d;
}

p {
  font-family: 'Open Sans', sans-serif;
  color: #1d1d1d;
  padding: 0.5rem 0;
}

@media screen and (max-width: 800px) {
  div {
    width: 100%;
  }
}
</style>

