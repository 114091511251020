<template>
  <div class="new-variant-container">
    <h1>Neue Variante anlegen</h1>
    <form @submit.prevent="createVariant" class="form-container">
      <div class="form-group">
        <label for="verantwortlich">Verantwortlicher Mitarbeiter:</label>
        <select id="verantwortlich" v-model="variantData.verantwortlich" required class="input-field">
          <option v-for="mitarbeiter in mitarbeiterListe" :key="mitarbeiter.mitarbeiternummer" :value="mitarbeiter.mitarbeiternummer">
            {{ mitarbeiter.name }} ({{ mitarbeiter.mitarbeiternummer }})
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="produkt">Produkt:</label>
        <select id="produkt" v-model="variantData.pnr" required class="input-field">
          <option v-for="produkt in produktListe" :key="produkt.produktnummer" :value="produkt.produktnummer">
            {{ produkt.name }} ({{ produkt.produktnummer }})
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="farbe">Farbe:</label>
        <input type="text" id="farbe" v-model="variantData.farbe" required class="input-field">
      </div>
      <div class="form-group">
        <label for="akku">Akku (mAh):</label>
        <input type="number" id="akku" v-model="variantData.akku" min="0" max="3000" required class="input-field">
      </div>
      <div class="form-group">
        <label for="speicher">Speicher:</label>
        <select id="speicher" v-model="variantData.speicher" required class="input-field">
          <option v-for="speicherwert in speicherOptionen" :key="speicherwert" :value="`${speicherwert} GB`">
            {{ speicherwert }} GB
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="groeße">Größe:</label>
        <select id="groeße" v-model="variantData.groeße" required class="input-field">
          <option v-for="zoll in zollOptionen" :key="zoll" :value="`${zoll} Zoll`">
            {{ zoll }} Zoll
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="ezpreis">Einzelpreis:</label>
        <input type="number" id="ezpreis" v-model="variantData.ezpreis" required class="input-field">
      </div>
      <button type="submit" class="submit-button">Variante anlegen</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "CreateVariantComponent",
  data() {
    return {
      mitarbeiterListe: [],
      produktListe: [],
      variantData: this.initVariantData(),
      speicherOptionen: this.erzeugeSpeicherOptionen(),
      zollOptionen: [10, 12, 15, 20, 25, 30],
    };
  },
  mounted() {
    this.fetchMitarbeiter();
    this.fetchProdukte();
  },
  methods: {
    initVariantData() {
      return {
        verantwortlich: "",
        pnr: "",
        farbe: "",
        akku: "",
        speicher: "", 
        groeße: "", 
        ezpreis: 0,
      };
    },
    async fetchMitarbeiter() {
      try {
        const response = await fetch('https://168.119.224.190:444/api/mitarbeiter/zeigeallemitarbeiter');
        const data = await response.json();
        this.mitarbeiterListe = data.data.map(m => ({ mitarbeiternummer: m.mitarbeiternummer, name: m.name }));
      } catch (error) {
        console.error("Fehler beim Abrufen der Mitarbeiter: ", error);
      }
    },
    async fetchProdukte() {
      try {
        const response = await fetch('https://168.119.224.190:444/api/produkt/zeigealleprodukte');
        const data = await response.json();
        this.produktListe = data.data.map(p => ({ produktnummer: p.produktnummer, name: p.bezeichnung }));
      } catch (error) {
        console.error("Fehler beim Abrufen der Produkte: ", error);
      }
    },
    validateVariantData() {
      if (this.variantData.akku < 0 || this.variantData.akku > 3000) {
        alert("Der Akku muss zwischen 0 und 3000 mAh liegen.");
        return false;
      }
      if (this.variantData.ezpreis <= 0) {
        alert("Der Einzelpreis muss größer als 0 sein.");
        return false;
      }
      if (!this.variantData.farbe.trim()) {
        alert("Eine Farbe muss angegeben werden.");
        return false;
      }
      return true;
    },
    erzeugeSpeicherOptionen() {
      const optionen = [];
      for (let i = 8; i <= 6000; i *= 1.5) {
        optionen.push(Math.round(i));
      }
      return optionen;
    },
    async createVariant() {
      if (!this.validateVariantData()) {
        return; 
      }
      try {
        const response = await fetch(`https://168.119.224.190:444/api/variante/varianteanlegen/${this.variantData.verantwortlich}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pnr: this.variantData.pnr,
            farbe: this.variantData.farbe,
            akku: `${this.variantData.akku} mAh`,
            speicher: this.variantData.speicher,
            groeße: this.variantData.groeße,
            ezpreis: this.variantData.ezpreis
          }),
        });

        if (!response.ok) {
          throw new Error('Fehler beim Anlegen der Variante');
        }

        const responseData = await response.json();
        alert("Variante erfolgreich angelegt!");
        console.log(responseData);
        this.variantData = this.initVariantData(); 
      } catch (error) {
        console.error("Fehler beim Anlegen der Variante: ", error);
        alert("Fehler beim Anlegen der Variante.");
      }
    },
  },
};
</script>

<style scoped>
.new-variant-container {
  padding: 0 0.625rem;
  flex: 0 0 auto;
  width: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% auto 3%;
}

.form-container {
  width: 100%;
  max-width: 600px;
  margin: 1rem 0;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.submit-button {
  width: 100%;
  padding: 0.75rem;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .new-variant-container {
    width: 80%;
  }
}
</style>
