<template>
  <div class="order-overview">
    <h1>Positionen Übersicht und Bewertung</h1>
    <p>Bitte geben Sie Ihre Kundennummer ein, um Ihre Bestellungen zu sehen und zu bewerten.</p>
    <input v-model="kundennummer" class="input-field" placeholder="Kundennummer eingeben" @keyup.enter="ladePositionen" />
    <div v-if="positionen.length > 0" class="positions-container">
      <div v-for="position in positionen" :key="position.bestellnummer" class="position-tile">
        <div class="position-content">
          <h2>Bestellnummer: {{ position.bestellnummer }}</h2>
          <h3>Position: {{ position.produktnummer }}-{{ position.variantennummer }}</h3>
          <div v-if="position.varianteDetails" class="variante-details">
            <div v-for="(wert, eigenschaft) in gefilterteDetails(position.varianteDetails)" :key="eigenschaft">
              {{ eigenschaft }}: {{ wert }}
            </div>
          </div>
          <div>
            <label>Bewertung:</label>
            <div class="rating">
              <span v-for="n in 5" :key="n" class="star" :class="{ selected: n <= position.bewertung.score }" @click="setRating(position, n)">
                &#9733;
              </span>
            </div>
            <input v-model="position.bewertung.text" placeholder="Kommentar" />
            <button @click="bewertePosition(position)">Bewerten</button>
          </div>
        </div>
        <div class="bewertungen-container">
          <h3>Vorhandene Bewertungen:</h3>
          <div v-for="bewertung in position.bewertungen" :key="bewertung.bewertungsnummer" class="bewertung-item">
            <span>{{ bewertung.score }} Sterne - {{ bewertung.text }}</span>
            <button @click="loescheBewertung(bewertung)">Löschen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
export default {
  data() {
    return {
      kundennummer: '',
      positionen: [],
    };
  },
  methods: {
    async ladePositionen() {
  try {
    const response = await fetch(`https://168.119.224.190:444/api/bestellung/zeigepositionenzukunde/${this.kundennummer}`);
    if (!response.ok) throw new Error('Fehler beim Laden der Positionen');
    const data = await response.json();
    this.positionen = data.data.map(position => ({
      ...position,
      bewertungen: [],
      bewertung: { score: 0, text: '' },
      varianteDetails: null,
    }));

    
    this.positionen.sort((a, b) => b.bestellnummer.localeCompare(a.bestellnummer));


    for (const position of this.positionen) {
      await this.ladeVarianteDetails(position.produktnummer, position.variantennummer, position);
      position.bewertungen = await this.ladeBewertungenZuBestellung(position.bestellnummer, position.produktnummer, position.variantennummer);
    }
  } catch (error) {
    console.error("Fehler beim Laden der Positionen", error);
  }
},

    async ladeVarianteDetails(produktnummer, variantennummer, position) {
      try {
        const response = await fetch(`https://168.119.224.190:444/api/variante/zeigevariante/${produktnummer}/${variantennummer}`);
        if (!response.ok) throw new Error('Fehler beim Laden der Variante');
        const data = await response.json();
        position.varianteDetails = data.data[0];
      } catch (error) {
        console.error("Fehler beim Laden der Variantendetails", error);
      }
    },
    async ladeBewertungenZuBestellung(bestellnummer, produktnummer, variantennummer) {
      try {
        const response = await fetch(`https://168.119.224.190:444/api/bewertung/zeigebewertungenzubestellung/${bestellnummer}`);
        if (!response.ok) throw new Error('Fehler beim Laden der Bewertungen');
        const data = await response.json();
        return data.data.filter(bewertung => bewertung.produktnummer === produktnummer && bewertung.variantennummer === variantennummer);
      } catch (error) {
        console.error("Fehler beim Laden der Bewertungen", error);
        return [];
      }
    },
    async bewertePosition(position) {
      try {
        const response = await fetch(`https://168.119.224.190:444/api/bewertung/bewertunganlegen`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bnr: position.bestellnummer,  
            pnr: position.produktnummer,  
            vnr: position.variantennummer,  
            score: position.bewertung.score, 
            text: position.bewertung.text,  
          }),
        });
        if (!response.ok) throw new Error('Fehler beim Senden der Bewertung');
        const responseData = await response.json();
        alert(responseData.message); 
        position.bewertung.score = 0; 
        position.bewertung.text = ''; 
        
        position.bewertungen = await this.ladeBewertungenZuBestellung(position.bestellnummer, position.produktnummer, position.variantennummer);
      } catch (error) {
        console.error("Fehler beim Senden der Bewertung", error);
        alert('Fehler beim Senden der Bewertung: ' + error.message);
      }
    },
    async loescheBewertung(bewertung) {
      try {
        const response = await fetch(`https://168.119.224.190:444/api/bewertung/bewertungloeschen/${bewertung.bestellnummer}/${bewertung.produktnummer}/${bewertung.variantennummer}/${bewertung.bewertungsnummer}`, {
          method: 'DELETE'
        });
        if (!response.ok) throw new Error('Fehler beim Löschen der Bewertung');
        const responseData = await response.json();
        alert(responseData.message);
      
        this.positionen.forEach(async (position) => {
          if (position.bestellnummer === bewertung.bestellnummer && position.produktnummer === bewertung.produktnummer && position.variantennummer === bewertung.variantennummer) {
            position.bewertungen = await this.ladeBewertungenZuBestellung(bewertung.bestellnummer, bewertung.produktnummer, bewertung.variantennummer);
          }
        });
      } catch (error) {
        console.error("Fehler beim Löschen der Bewertung", error);
      }
    },
    gefilterteDetails(details) {
      const auszuschließendeSchlüssel = ['produktnummer', 'variantennummer'];
      return Object.keys(details).reduce((acc, key) => {
        if (!auszuschließendeSchlüssel.includes(key)) {
          acc[key] = details[key];
        }
        return acc;
      }, {});
    },
    setRating(position, score) {
      position.bewertung.score = score;
    },
  }
};

</script>
<style scoped>
.order-overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 650px;
}

.input-field {
  font-family: 'Open Sans', sans-serif !important;
  color: #1d1d1d;
  padding: 0.5rem 1rem;
  border-color: #000;
  border-width: 1.5px;
  width: 40%;
  border-radius: 50rem !important;
  margin: 1rem 0;
}

.position-tile {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 1rem;
  padding: 0.6rem;
  width: calc(60% - 20rem);
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.position-content, .bewertungen-container {
  flex-grow: 1;
  padding: 0 10px;
}

.bewertungen-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 300px;
}

button {
  background-color: #222222;
  color: #fffdfd;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 0.7rem;
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 0.5rem;
}

.bewertung-item {
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.rating .star {
  cursor: pointer;
  font-size: 1.5rem;
  color: grey;
}

.rating .star.selected {
  color: gold;
}

.positions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}


input[type="text"] {
  width: 100%; 
  padding: 0.5rem; 
  margin-top: 0.5rem; 
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px; 
}

@media (max-width: 768px) {
  .input-field {
    width: 80%; 
  }

  .position-tile {
    flex-direction: column; 
    width: 90%; 
    padding: 1rem; 
  }

  .position-content, .bewertungen-container {
    flex-grow: 0; 
    padding: 0; 
    max-width: 100%; 
  }

  .bewertungen-container {
    align-items: stretch; 
  }

  button {
    width: auto; 
    font-size: 1rem; 
  }

  .bewertung-item {
    align-items: center; 
  }

  .rating .star {
    font-size: 2rem; 
  }
  
  
  input[type="text"] {
    font-size: 0.9rem; 
  }
}
</style>



