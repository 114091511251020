<template>
  <div class="new-customer-container">
    <h1>Neuen Kunden anlegen</h1>
    <form @submit.prevent="createCustomer" class="form-container">
      <div class="form-group">
        <label for="firmenbezeichnung">Firmenbezeichnung:</label>
        <input type="text" id="firmenbezeichnung" v-model="customerData.fbez" required class="input-field">
      </div>
      <div class="form-group">
        <label for="strasse">Straße:</label>
        <input type="text" id="strasse" v-model="customerData.str" required class="input-field">
      </div>
      <div class="form-group">
        <label for="hausnummer">Hausnummer:</label>
        <input type="text" id="hausnummer" v-model="customerData.hnr" required class="input-field">
      </div>
      <div class="form-group">
        <label for="plz">PLZ:</label>
        <input type="text" id="plz" v-model="customerData.plz" required class="input-field">
      </div>
      <div class="form-group">
        <label for="ort">Ort:</label>
        <input type="text" id="ort" v-model="customerData.ort" required class="input-field">
      </div>
      <div class="form-group">
        <label for="ustid">USt-IdNr:</label>
        <input type="text" id="ustid" v-model="customerData.ustid" required class="input-field">
      </div>
      <div class="form-group">
        <label for="mailadresse">E-Mail-Adresse:</label>
        <input type="email" id="mailadresse" v-model="customerData.mail" required class="input-field">
      </div>
      <div class="form-group">
        <label for="kreditlimit">Optionale Felder:</label>
      </div>
      <div class="form-group">
        <label for="kreditlimit">Kreditlimit:</label>
        <input type="number" id="kreditlimit" v-model="customerData.krlim" class="input-field">
      </div>
      <div class="form-group">
        <label for="zahlungsziel">Zahlungsziel:</label>
        <input type="text" id="zahlungsziel" v-model="customerData.zahlz" class="input-field">
      </div>
      <div class="form-group">
        <label for="rabattsatz">Rabattsatz:</label>
        <input type="number" id="rabattsatz" v-model="customerData.rasa" class="input-field">
      </div>
      <button type="submit" class="submit-button">Kunden anlegen</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "CreateCustomerComponent",
  data() {
    return {
      customerData: {
        fbez: "",
        str: "",
        hnr: "",
        plz: "",
        ort: "",
        ustid: "",
        mail: "",
        krlim: null,
        zahlz: null,
        rasa: null,
      },
    };
  },
  methods: {
    async createCustomer() {
  if (!this.validateCustomerData()) {
    
    return;
  }

  // Standardwerte setzen, wenn die Felder leer sind
  this.customerData.krlim = this.customerData.krlim || 0;
  this.customerData.zahlz = this.customerData.zahlz || "";
  this.customerData.rasa = this.customerData.rasa || 0;

  // Daten für die Überprüfung ausgeben
  console.log("Daten, die an den Server gesendet werden:", JSON.stringify(this.customerData));

  try {
    const response = await fetch('https://168.119.224.190:444/api/kunden/anlegen', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.customerData),
    });

    if (!response.ok) {
      throw new Error('Fehler beim Anlegen des Kunden');
    }

    const responseData = await response.json();
    alert(responseData.message);
    console.log(responseData); 
  } catch (error) {
    console.error("Fehler beim Anlegen des Kunden: ", error);
    alert("Fehler beim Anlegen des Kunden.");
  }
},
    validateCustomerData() {
      let isValid = true;
      
      if (!this.customerData.fbez || this.customerData.fbez.length < 3) {
        isValid = false;
        alert("Firmenbezeichnung muss mindestens 3 Zeichen lang sein.");
      }
      if (!this.customerData.mail || !/\S+@\S+\.\S+/.test(this.customerData.mail)) {
        isValid = false;
        alert("E-Mail-Adresse ist ungültig.");
      }
      if (this.customerData.krlim < 0) {
        isValid = false;
        alert("Kreditlimit darf nicht negativ sein.");
      }
      if (!/^DE[0-9]{9}$/.test(this.customerData.ustid)) {
      isValid = false;
      alert("USt-IdNr. muss dem Format DE + 9 Zahlen entsprechen.");
  }
      
      
      
      
      return isValid;
    }
  },
};

</script>

<style scoped>
.new-customer-container {
  padding: 0 0.625rem;
  flex: 0 0 auto;
  width: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% auto 3%;
  gap: 1rem;
}

.form-container {
  width: 100%;
  max-width: 600px;
  margin: 1rem 0;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
}

button[type="submit"] {
  padding: 0.875rem; 
  color: #fff;
  background-color: #000000;
  border-color: #000000;
  border-radius: 0.5rem;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}
</style>
