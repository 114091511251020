<template>
    <div id="app">
      
     <BewertenPosition /> 
     
  
    </div>
  </template>
  
  <script>
  
  import BewertenPosition from '@/components/BewertenPosition.vue';

  
  export default {
    name: 'App',
    components: {
    
    BewertenPosition
    
  },
    mounted() {
      window.scrollTo(0, 0);
    }
    }
  </script>
  
  <style>
  
  </style>../components/BewertenPosition.vue