<template>
  <div id="app">
    
    <createKunde />
    
  </div>
</template>

<script>



import createKunde from '../components/createKunde.vue';






export default {
  name: 'App',
  components: {
  
  createKunde
  
},
mounted() {
  window.scrollTo(0, 0);
}
}
</script>

<style>

</style>