<template>
    <div id="app">
      
      <employeeOverview/>
      
    </div>
  </template>
  
  <script>
  
  
  
  import employeeOverview from '../components/employeeOverview.vue';
  
  
  
  
  
  
  export default {
    name: 'App',
    components: {
    
    employeeOverview
    
  },
  mounted() {
    window.scrollTo(0, 0);
  }
  }
  </script>
  
  <style>
  
  </style>