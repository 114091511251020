<template>
  <div class="new-product-container">
    <h1>Neues Produkt anlegen</h1>
    <form @submit.prevent="createProduct" class="form-container">
      <div class="form-group">
        <label for="verantwortlich">Verantwortlicher Mitarbeiter:</label>
        <select id="verantwortlich" v-model="productData.verantwortlich" required class="input-field">
          <option v-for="mitarbeiter in mitarbeiterListe" :key="mitarbeiter.mitarbeiternummer" :value="mitarbeiter.mitarbeiternummer">
            {{ mitarbeiter.name }} ({{ mitarbeiter.mitarbeiternummer }})
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="bez">Bezeichnung:</label>
        <input type="text" id="bez" v-model="productData.bez" required class="input-field">
      </div>
      <div class="form-group">
        <label for="wkz">Währung:</label>
        <select id="wkz" v-model="productData.wkz" required class="input-field">
          <option v-for="currency in currencyList" :key="currency" :value="currency">
            {{ currency }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="farbe">Farbe:</label>
        <input type="text" id="farbe" v-model="productData.farbe" required class="input-field">
      </div>
      <div class="form-group">
        <label for="akku">Akku:</label>
        <input type="number" id="akku" v-model="productData.akku" min="0" max="3000" required class="input-field">
        <span>mAh</span>
      </div>
      <div class="form-group">
        <label for="speicher">Speicher:</label>
        <select id="speicher" v-model="productData.speicher" required class="input-field">
          <option v-for="speicherwert in speicherOptionen" :key="speicherwert" :value="`${speicherwert} GB`">
            {{ speicherwert }} GB
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="groeße">Größe:</label>
        <select id="groeße" v-model="productData.groeße" required class="input-field">
          <option v-for="zoll in zollOptionen" :key="zoll" :value="`${zoll} Zoll`">
            {{ zoll }} Zoll
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="ezpreis">Einzelpreis:</label>
        <input type="number" id="ezpreis" v-model="productData.ezpreis" required class="input-field">
      </div>
      <button type="submit" class="submit-button">Produkt anlegen</button>
    </form>
    <button type="button" @click="goBack" class="back-button">Zurück</button>
  </div>
</template>

<script>
export default {
  name: "CreateProductComponent",
  data() {
    return {
      mitarbeiterListe: [],
      productData: this.initProductData(),
      currencyList: ['EUR', 'USD', 'GBP', 'JPY', 'CHF', 'CNY', 'SEK', 'NZD', 'CAD', 'AUD'],
      speicherOptionen: this.erzeugeSpeicherOptionen(),
      zollOptionen: [10, 12, 15, 20, 25, 30],
    };
  },
  mounted() {
    this.fetchMitarbeiter();
  },
  methods: {
    initProductData() {
      return {
        verantwortlich: "",
        bez: "",
        wkz: "EUR", 
        farbe: "",
        akku: 0,
        speicher: "8 GB",
        groeße: "10 Zoll",
        ezpreis: 0,
      };
    },
    async fetchMitarbeiter() {
      try {
        const response = await fetch('https://168.119.224.190:444/api/mitarbeiter/zeigeallemitarbeiter');
        const data = await response.json();
        this.mitarbeiterListe = data.data.map(m => ({ mitarbeiternummer: m.mitarbeiternummer, name: m.name }));
      } catch (error) {
        console.error("Fehler beim Abrufen der Mitarbeiter: ", error);
      }
    },
    erzeugeSpeicherOptionen() {
      const optionen = [];
      for (let i = 8; i <= 512; i *= 2) { 
        optionen.push(i);
      }
      return optionen;
    },
    validateProductData() {
      if (this.productData.ezpreis <= 0) {
        alert("Der Einzelpreis muss größer als 0 sein.");
        return false;
      }
      if (this.productData.akku <= 0 || this.productData.akku > 3000) {
        alert("Der Akku muss zwischen 1 und 3000 mAh liegen.");
        return false;
      }

      
      return true;
    },
    async createProduct() {
      if (!this.validateProductData()) {
        return;
      }

      try {
        const response = await fetch(`https://168.119.224.190:444/api/produkt/produktanlegen`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.productData),
        });

        if (!response.ok) {
          throw new Error('Fehler beim Anlegen des Produkts');
        }

        const responseData = await response.json();
        alert("Produkt erfolgreich angelegt!");
        console.log(responseData);
        this.productData = this.initProductData();
      } catch (error) {
        console.error("Fehler beim Anlegen des Produkts: ", error);
        alert("Fehler beim Anlegen des Produkts: " + error.message);
      }
    },
    goBack() {
      window.history.back();
    },
  },
};

</script>

<style scoped>
.new-product-container {
  padding: 0 0.625rem;
  flex: 0 0 auto;
  width: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% auto 3%;
}

.form-container {
  width: 100%;
  max-width: 600px;
  margin: 1rem 0;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.submit-button,
.back-button {
  width: 100%;
  padding: 0.75rem;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.back-button {
  margin-top: 0.5rem;
  background-color: #000000;
}

@media (max-width: 768px) {
  .new-product-container {
    width: 80%;
  }
}
</style>
