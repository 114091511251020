<template>
  <div>
    <div v-if="produkt">
      <h2>Produktdetails</h2>
      <p>Bezeichnung: {{ produkt.bezeichnung }}</p>
      <p>Produktnummer: {{ produkt.produktnummer }}</p>
      <div class="button-container">
        <button @click="zurueck">Zurück</button>
        <button @click="loescheProdukt(produkt.produktnummer)">Löschen</button>
        <button @click="bearbeiteProdukt">Bearbeiten</button>
      </div>
      <div v-if="varianten.length">
        <h3>Produktvarianten</h3>
        <div v-for="variante in varianten" :key="variante.variantennummer" class="variante-kachel" @click="zeigeVarianteDetails(produkt.produktnummer, variante.variantennummer)">
          <p>Variantennummer: {{ variante.variantennummer }}</p>
          <p>Variantenbezeichnung: {{ variante.bezeichnung }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        produkt: null,
        varianten: [],
      };
    },
    mounted() {
      this.ladeProduktDetails();
      this.ladeVarianten();
    },
    methods: {
      ladeProduktDetails() {
        const pnr = this.$route.params.pnr;
        fetch(`https://168.119.224.190:444/api/produkt/zeigeprodukt/${pnr}`)
          .then((response) => response.json())
          .then((data) => {
            this.produkt = data.data[0];
          })
          .catch((error) => console.error('Fehler:', error));
      },
      ladeVarianten() {
        const pnr = this.$route.params.pnr;
        fetch(`https://168.119.224.190:444/api/produkt/zeigevariantenzuprodukt/${pnr}`)
          .then((response) => response.json())
          .then((data) => {
            this.varianten = data.data;
          })
          .catch((error) => console.error('Fehler:', error));
      },
      loescheProdukt(pnr) {
        if (this.varianten.length > 0) {
      alert('Löschen nicht möglich: Es existieren zugeordnete Bestellpositionen.');
      return; 
    }
        fetch(`https://168.119.224.190:444/api/produkt/produktloeschen/${pnr}`, { method: 'DELETE' })
          .then(() => {
            this.$router.push({ name: 'ProductOverview' });
          })
          .catch((error) => console.error('Fehler:', error));
      },
      bearbeiteProdukt() {
        this.$router.push({ name: 'ProductEdit', params: { pnr: this.produkt.produktnummer } });
      },
      ladeVarianteDetails() {
        const pnr = this.$route.params.pnr;
        const vnr = this.$route.params.vnr;
        fetch(`https://168.119.224.190:444/api/variante/zeigevariante/${pnr}/${vnr}`)
          .then((response) => response.json())
          .then((data) => {
            this.variante = data.data[0];
          })
          .catch((error) => console.error('Fehler:', error));
        },
      zurueck() {
         this.$router.go(-1);
        },
        zeigeVarianteDetails(pnr, vnr) {
        this.$router.push({ name: 'VariantDetails', params: { pnr, vnr } });
       }
    },
  };
  </script>
  
  
  
  <style scoped>
  div {
    padding: 0 0.625rem !important;
    flex: 0 0 auto;
    width: 60%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2% auto;
    gap: 1rem;
  }
  
  h2, h3 {
    font-family: 'Open Sans', sans-serif !important;
    color: #1d1d1d;
  }
  
  p {
    font-family: 'Open Sans', sans-serif !important;
    color: #1d1d1d;
    padding: 0.5rem 0;
  }
  
  .variante-kachel {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 10px; 
    background-color: transparent;
    color: #1d1d1d;
    font-family: 'Open Sans', sans-serif !important;
    width: 95%;
  }
  
  .button-container {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    width: 100%;
    margin-bottom: 1rem; 
  }
  
  button {
    background-color: #222222;
    color: #fffdfd;
    font-family: 'Open Sans', sans-serif !important;
    font-size: 1rem !important;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    padding: 0.4rem 0.8rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  @media screen and (max-width: 800px) {
    div {
      width: 100%;
    }
  }
  </style>