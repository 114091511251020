<template>
  <div class="new-employee-container">
    <h1>Neuen Mitarbeiter anlegen</h1>
    <form @submit.prevent="createEmployee" class="form-container">
      <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="employeeData.name" required class="input-field">
      </div>
      <div class="form-group">
        <label for="pos">Position:</label>
        <input type="text" id="pos" v-model="employeeData.pos" required class="input-field">
      </div>
      <div class="form-group">
        <label for="gehalt">Gehalt:</label>
        <input type="number" id="gehalt" v-model="employeeData.gehalt" required class="input-field">
      </div>
      <div class="form-group">
        <label for="wochh">Wochenstunden:</label>
        <input type="number" id="wochh" v-model="employeeData.wochh" required class="input-field">
      </div>
      <div class="form-group">
        <label for="produkt">Produkt:</label>
        <select id="produkt" v-model="selectedProduct" @change="fetchVarianten" required class="input-field">
          <option v-for="produkt in produkte" :key="produkt.produktnummer" :value="produkt.produktnummer">
            {{ produkt.bezeichnung }}
          </option>
        </select>
      </div>
      <div class="form-group" v-if="varianten && varianten.length">
        <label for="variante">Variante:</label>
        <select id="variante" v-model="selectedVariante" required class="input-field">
          <option v-for="variante in varianten" :key="variante.variantennummer" :value="variante.variantennummer">
            {{ variante.variantennummer }}
          </option>
        </select>
      </div>
      <button type="submit" class="submit-button">Mitarbeiter anlegen</button>
    </form>
    <button type="button" @click="goBack" class="back-button">Zurück</button>
  </div>
</template>

<script>
export default {
  name: "CreateEmployeeComponent",
  data() {
    return {
      produkte: [],
      varianten: [],
      selectedProduct: null,
      selectedVariante: null,
      employeeData: {
        name: "",
        pos: "",
        gehalt: null,
        wochh: null
      },
    };
  },
  mounted() {
    this.fetchProdukte();
  },
  methods: {
    async fetchProdukte() {
      try {
        const response = await fetch('https://168.119.224.190:444/api/produkt/zeigealleprodukte');
        const data = await response.json();
        this.produkte = data.data;
        
        if (this.produkte.length > 0) {
          this.selectedProduct = this.produkte[0].produktnummer;
          this.fetchVarianten();
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Produkte: ", error);
      }
    },
    async fetchVarianten() {
      try {
        if (!this.selectedProduct) {
          this.varianten = [];
          return;
        }
        const response = await fetch(`https://168.119.224.190:444/api/produkt/zeigevariantenzuprodukt/${this.selectedProduct}`);
        const data = await response.json();
        this.varianten = data.data;
      } catch (error) {
        console.error("Fehler beim Abrufen der Varianten: ", error);
      }
    },
    async createEmployee() {

      if (!this.validateEmployeeData()) {
      return; 
    }
      if (!this.selectedProduct || !this.selectedVariante) {
        alert("Bitte wählen Sie ein Produkt und eine Variante aus.");
        return;
      }
      try {
        const response = await fetch(`https://168.119.224.190:444/api/mitarbeiter/anlegen/${this.selectedProduct}/${this.selectedVariante}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.employeeData),
        });
        if (!response.ok) {
          throw new Error('Fehler beim Anlegen des Mitarbeiters');
        }
        const responseData = await response.json();
        alert(responseData.message);
        this.resetForm();
      } catch (error) {
        console.error("Fehler beim Anlegen des Mitarbeiters: ", error);
        alert("Fehler beim Anlegen des Mitarbeiters.");
      }
    },
    validateEmployeeData() {
    const errors = [];
    if (!this.employeeData.name) {
      errors.push("Name ist erforderlich.");
    }
    if (!this.employeeData.pos) {
      errors.push("Position ist erforderlich.");
    }
    if (this.employeeData.gehalt <= 0) {
      errors.push("Gehalt muss größer als 0 sein.");
    }
    if (this.employeeData.gehalt > 500000) {
      errors.push("Gehalt muss kleiner als 500000 sein.");
    }
    if (this.employeeData.wochh < 20 || this.employeeData.wochh > 40) {
      errors.push("Wochenstunden müssen zwischen 20 und 40 liegen.");
    }
    if (!this.selectedProduct) {
      errors.push("Produkt ist erforderlich.");
    }
    if (!this.selectedVariante) {
      errors.push("Variante ist erforderlich.");
    }

    if (errors.length > 0) {
      alert(errors.join("\n"));
      return false;
    }
    return true;
  },

    resetForm() {
      this.employeeData = {
        name: "",
        pos: "",
        gehalt: null,
        wochh: null
      };
      
      this.selectedProduct = (this.produkte.length > 0) ? this.produkte[0].produktnummer : null;
      this.fetchVarianten();
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style scoped>
.new-employee-container {
  padding: 0 0.625rem;
  flex: 0 0 auto;
  width: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% auto 3%;
  gap: 1rem;
}

.form-container {
  width: 100%;
  max-width: 600px;
  margin: 1rem 0;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
}

select.input-field {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='%23212121' width='18px' height='18px'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 1.5rem;
  padding-right: 2.5rem;
}

button[type="submit"], .back-button {
  padding: 0.875rem;
  color: #fff;
  background-color: #000000;
  border-color: #000000;
  border-radius: 0.5rem;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.back-button {
  background-color: #000000;
  border-color: #000000;
}
</style>
