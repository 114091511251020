<template>
  <div class="dashboard">
    <div class="kachel" v-for="item in verwaltungsItems" :key="item.title">
      <h2>{{ item.title }}</h2>
      <nav>
        <ul>
          <li v-for="link in item.links" :key="link.name">
            <router-link :to="link.url">{{ link.name }}</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerwaltungKachel',
  data() {
    return {
      verwaltungsItems: [
        {
          title: 'Kunden',
          links: [
            { name: 'Verwalten', url: '/Administration/Customer/all' },
            { name: 'Anlegen', url: '/Administration/Customer/Create' },
          ],
        },
        {
          title: 'Varianten',
          links: [
            { name: 'Verwalten', url: '/Administration/Variant/all' },
            { name: 'Anlegen', url: '/Administration/Variant/Create' },
          ],
        },
        {
          title: 'Produkte',
          links: [
            { name: 'Verwalten', url: '/Administration/Product/all' },
            { name: 'Anlegen', url: '/Administration/Product/Create' },
          ],
        },
        {
          title: 'Mitarbeiter',
          links: [
            { name: 'Verwalten', url: '/Administration/Employee/all' },
            { name: 'Anlegen', url: '/Administration/Employee/Create' },
          ],
        },
        {
          title: 'Zuordnungen',
          links: [
            { name: 'Verwalten', url: '/Administration/Employee/Assignment/all' },
            { name: 'neue Zuordnung', url: '/Administration/Employee/Assignment' },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.kachel {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: transparent;
  color: #1d1d1d;
  font-family: 'Open Sans', sans-serif;
  cursor: pointer;
  width: 95%;
}

.kachel h2 {
  font-family: 'Open Sans', sans-serif;
  color: #222222;
}

.kachel ul {
  list-style-type: none;
  padding-left: 0;
}

.kachel li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.router-link {
  text-decoration: none;
  color: #0056b3;
  font-family: 'Open Sans', sans-serif;
}

.router-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .kachel {
    width: 100%;
  }
}
</style>
