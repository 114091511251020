<template>
    <div>
      <h1>Kundenübersicht</h1>
      <div v-for="kunde in kunden" :key="kunde.kundennummer" @click="zeigeKundeDetails(kunde.kundennummer)" class="kunde">
        <h3>{{ kunde.firmenbezeichnung }}</h3>
        <p>Kundennummer: {{ kunde.kundennummer }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        kunden: [],
      };
    },
    mounted() {
      this.ladeKunden();
    },
    methods: {
      ladeKunden() {
        fetch('https://168.119.224.190:444/api/kunden/zeigeallekunden')
          .then((response) => response.json())
          .then((data) => {
            this.kunden = data.data;
          })
          .catch((error) => console.error('Fehler:', error));
      },
      zeigeKundeDetails(kundennummer) {
        this.$router.push({ name: 'CustomerDetail', params: { kdnr: kundennummer } });
      },
    },
  };
  </script>
  
  <style scoped>
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem; 
  }
  
  .kunde {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1.2rem; 
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: transparent;
    color: #1d1d1d;
    font-family: 'Open Sans', sans-serif !important;
    margin-top: 1.5rem;
    margin-bottom: 1rem; 
    width: 95%; 
    max-width: 600px; 
  }
  
  .kunde:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  }
  
  .kunde h3 {
    font-family: 'Open Sans', sans-serif !important;
    color: #222222;
    margin: 0 0 1rem 0; 
  }
  
  .kunde p {
    font-family: 'Open Sans', sans-serif !important;
    color: #1d1d1d;
    margin: 0;
  }
  
  @media screen and (max-width: 800px) {
    .kunde {
      padding: 1rem; 
      width: 95%; 
    }
  }
  </style>
  