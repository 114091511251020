<template>
    <div id="app">
      
      
      <variantDetail/>
      
    </div>
</template>
  
<script>
  
  
  import variantDetail from '../components/variantDetail.vue';
  
  
  
  
  
  
  
  export default {
    name: 'App',
    components: {
    
    variantDetail
    
  },
  mounted() {
    window.scrollTo(0, 0);
  }
  }
</script>
  
<style>
  
</style>