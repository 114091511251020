<template>
  <div class="mitarbeiter-zuordnen-container">
    <h1>Mitarbeiter Zuordnung</h1>
    <form @submit.prevent="zuordnungSpeichern" class="form-container">
      
      <div class="form-group">
        <label for="mitarbeiter">Mitarbeiter:</label>
        <select id="mitarbeiter" v-model="selectedMitarbeiter" @change="mitarbeiterAusgewaehlt" required class="input-field">
          <option v-for="mitarbeiter in mitarbeiterListe" :key="mitarbeiter.mitarbeiternummer" :value="mitarbeiter.mitarbeiternummer">
            {{ mitarbeiter.name }}
          </option>
        </select>
      </div>
      
      <div class="form-group">
        <label for="produkt">Produkt:</label>
        <select id="produkt" v-model="selectedProdukt" @change="produktAusgewaehlt" required class="input-field">
          <option v-for="produkt in produkteListe" :key="produkt.produktnummer" :value="produkt.produktnummer">
            {{ produkt.bezeichnung }}
          </option>
        </select>
      </div>
      
      <div class="form-group" v-if="variantenListe && variantenListe.length">
        <label for="variante">Variante:</label>
        <select id="variante" v-model="selectedVariante" required class="input-field">
          <option v-for="variante in variantenListe" :key="variante.variantennummer" :value="variante.variantennummer">
            {{ variante.variantennummer }}
          </option>
        </select>
      </div>
      <div class="button-container">
        <button type="button" @click="zurueck" class="back-button">Zurück</button>
        <button type="submit" class="submit-button">Speichern</button>
        
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "MitarbeiterZuordnenKomponente",
  data() {
    return {
      mitarbeiterListe: [],
      produkteListe: [],
      variantenListe: [],
      selectedMitarbeiter: null,
      selectedProdukt: null,
      selectedVariante: null,
    };
  },
  mounted() {
    this.mitarbeiterLaden();
    this.produkteLaden();
  },
  methods: {
    async mitarbeiterLaden() {
      try {
        const response = await fetch('https://168.119.224.190:444/api/mitarbeiter/zeigeallemitarbeiter');
        const data = await response.json();
        this.mitarbeiterListe = data.data;
      } catch (error) {
        console.error("Fehler beim Laden der Mitarbeiter:", error);
      }
    },
    async produkteLaden() {
      try {
        const response = await fetch('https://168.119.224.190:444/api/produkt/zeigealleprodukte');
        const data = await response.json();
        this.produkteListe = data.data;
      } catch (error) {
        console.error("Fehler beim Laden der Produkte:", error);
      }
    },
    async produktAusgewaehlt() {
      try {
        if (!this.selectedProdukt) {
          this.variantenListe = [];
          return;
        }
        const response = await fetch(`https://168.119.224.190:444/api/produkt/zeigevariantenzuprodukt/${this.selectedProdukt}`);
        const data = await response.json();
        this.variantenListe = data.data;
      } catch (error) {
        console.error("Fehler beim Laden der Varianten:", error);
      }
    },
    validateZuordnungData() {
      if (!this.selectedMitarbeiter) {
        alert("Bitte wählen Sie einen Mitarbeiter aus.");
        return false;
      }
      if (!this.selectedProdukt) {
        alert("Bitte wählen Sie ein Produkt aus.");
        return false;
      }
      if (!this.selectedVariante) {
        alert("Bitte wählen Sie eine Variante aus.");
        return false;
      }
      
      return true;
    },
    async zuordnungSpeichern() {
      if (!this.validateZuordnungData()) {
        return; 
      }
      try {
        const response = await fetch(`https://168.119.224.190:444/api/mitarbeiter/zuordnunganlegen/${this.selectedMitarbeiter}/${this.selectedProdukt}/${this.selectedVariante}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Fehler beim Speichern der Zuordnung');
        }
        const responseData = await response.json();
        alert(responseData.message);
      } catch (error) {
        console.error("Fehler beim Speichern der Zuordnung: ", error);
        alert(`Fehler beim Speichern der Zuordnung: ${error.message}`);
      }
    },
    zurueck() {
      window.history.back();
    },
    mitarbeiterAusgewaehlt() {
      
    },
  },
};

</script>

<style scoped>
.mitarbeiter-zuordnen-container {
  padding: 0 0.625rem;
  flex: 0 0 auto;
  width: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% auto 3%;
}

.form-container {
  width: 100%;
  max-width: 600px;
  margin: 1rem 0;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-button,
.back-button {
  flex: 1;
  padding: 0.5rem;
  color: #fff;
  background-color: #000000;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 1rem;
}

.back-button {
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: #000000;
}
</style>
