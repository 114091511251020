<template>
  <h1>Varianten bearbeiten</h1>
  <div class="form-container">

  
    <form @submit.prevent="aktualisiereVariante" class="kunden-form">
      <div class="form-field">
        <label for="farbe">Farbe:</label>
        <input id="farbe" v-model="variante.farbe" type="text">
      </div>

      <div class="form-field">
        <label for="akku">Akku:</label>
        <input id="akku" v-model="variante.akku" type="text">
      </div>

      <div class="form-field">
        <label for="speicher">Speicher:</label>
        <input id="speicher" v-model="variante.speicher" type="text">
      </div>

      <div class="form-field">
        <label for="groesse">Größe:</label>
        <input id="groesse" v-model="variante.groeße" type="text">
      </div>

      <div class="form-field">
        <label for="ezpreis">Einzelhandelspreis:</label>
        <input id="ezpreis" v-model="variante.ezpreis" type="number">
      </div>

      <div class="button-container">
        <button @click="abbruch" type="button" class="aktion-btn abbruch">Abbruch</button>
        <button type="submit" class="aktion-btn">Speichern</button>
      </div>
    </form>
  </div>
</template>
  
  <script>
  export default {
  data() {
    return {
      variante: {
        produktnummer: this.$route.params.pnr,
        variantennummer: this.$route.params.vnr,
        farbe: '',
        akku: '',
        speicher: '',
        groeße: '',
        ezpreis: 0,
      },
    };
  },
  mounted() {
    this.ladeVarianteDetails();
  },
  methods: {
    ladeVarianteDetails() {
      const pnr = this.$route.params.pnr;
      const vnr = this.$route.params.vnr;
      fetch(`https://168.119.224.190:444/api/variante/zeigevariante/${pnr}/${vnr}`)
        .then(response => response.json())
        .then(data => {
          this.variante = data.data[0]; 
        })
        .catch(error => console.error('Fehler:', error));
    },
    validateVariante() {
      if (!this.variante.farbe.trim()) {
        alert("Die Farbe darf nicht leer sein.");
        return false;
      }
      if (!this.variante.akku.trim()) {
        alert("Das Akkufeld darf nicht leer sein.");
        return false;
      }
      if (!this.variante.speicher.trim()) {
        alert("Das Speicherfeld darf nicht leer sein.");
        return false;
      }
      if (!this.variante.groeße.trim()) {
        alert("Das Größenfeld darf nicht leer sein.");
        return false;
      }
      if (this.variante.ezpreis <= 0) {
        alert("Der Einzelhandelspreis muss größer als 0 sein.");
        return false;
      }
      
      return true;
    },
    aktualisiereVariante() {
      if (!this.validateVariante()) {
        return; 
      }

      const datenZumSenden = {
        farbe: this.variante.farbe,
        akku: this.variante.akku,
        speicher: this.variante.speicher,
        groeße: this.variante.groeße,
        ezpreis: parseFloat(this.variante.ezpreis),
      };
  
      fetch(`https://168.119.224.190:444/api/variante/variantebearbeiten/${this.variante.produktnummer}/${this.variante.variantennummer}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datenZumSenden),
      })
      .then(() => {
        this.$router.push({ name: 'VariantDetails', params: { pnr: this.variante.produktnummer, vnr: this.variante.variantennummer } });
      })
      .catch((error) => console.error('Fehler:', error));
    },
    abbruch() {
      this.$router.go(-1);
    },
  },
};

  </script>

<style scoped>
h1 {
  text-align: center;
  margin-bottom: 2rem;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.kunden-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 500px; 
}

.form-field {
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.1rem;
}

input[type="text"], input[type="number"], input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.aktion-btn {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.aktion-btn:hover, .aktion-btn:focus {
  background-color: #000000;
  color: #fff;
}

.abbruch {
  background-color: #000000;
  color: #fff;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .kunden-form {
    width: 90%;
  }
}
</style>

  