<template>
    <div>
      <div v-for="produkt in produkte" :key="produkt.produktnummer" class="produkt-kachel" @click="zeigeProduktDetails(produkt.produktnummer)">
        <h3>{{ produkt.bezeichnung }}</h3>
        <p>Produktnummer: {{ produkt.produktnummer }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        produkte: [],
      };
    },
    mounted() {
      this.ladeProdukte();
    },
    methods: {
      ladeProdukte() {
        fetch('https://168.119.224.190:444/api/produkt/zeigealleprodukte')
          .then((response) => response.json())
          .then((data) => {
            this.produkte = data.data;
          })
          .catch((error) => console.error('Fehler:', error));
      },
      zeigeProduktDetails(pnr) {
        this.$router.push({ name: 'ProductDetails', params: { pnr } });
      },
    },
  };
  </script>
  
  <style scoped>
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
  
  .produkt-kachel {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1.2rem;
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: transparent;
    color: #1d1d1d;
    font-family: 'Open Sans', sans-serif !important;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 95%;
    max-width: 600px;
  }
  
  .produkt-kachel:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  }
  
  .produkt-kachel h3 {
    font-family: 'Open Sans', sans-serif !important;
    color: #222222;
    margin: 0 0 1rem 0;
  }
  
  .produkt-kachel p {
    font-family: 'Open Sans', sans-serif !important;
    color: #1d1d1d;
    margin: 0;
  }
  
  @media screen and (max-width: 800px) {
    .produkt-kachel {
      padding: 1rem;
      width: 95%;
    }
  }
  </style>
  
  