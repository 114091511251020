<template>#
<h1>Mitarbeiter bearbeiten</h1> 
  <div class="form-container">
    <form @submit.prevent="aktualisiereMitarbeiter" class="kunden-form">
      <div class="form-field">
        <label>Name:</label>
        <input v-model="mitarbeiter.name" type="text">
      </div>

      <div class="form-field">
        <label>Mitarbeiternummer:</label>
        <input v-model="mitarbeiter.mitarbeiternummer" type="text" disabled>
      </div>

      <div class="form-field">
        <label>Stelle:</label>
        <input v-model="mitarbeiter.stelle" type="text">
      </div>

      <div class="form-field">
        <label>Gehalt:</label>
        <input v-model="mitarbeiter.gehalt" type="number">
      </div>

      <div class="form-field">
        <label>Wochenstunden:</label>
        <input v-model.number="mitarbeiter.wochenstunden" type="number" @blur="validiereWochenstunden">
        <p v-if="wochenstundenWarnung" class="warnung">{{ wochenstundenWarnung }}</p>
      </div>

      <div class="button-container">
        <button @click="abbruch" type="button" class="aktion-btn abbruch">Abbruch</button>
        <button type="submit" class="aktion-btn">Speichern</button>
        
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mitarbeiter: {
        mitarbeiternummer: this.$route.params.manr,
        name: '',
        stelle: '',
        gehalt: 0,
        wochenstunden: 0,
      },
      wochenstundenWarnung: '', 
    };
  },
  mounted() {
    this.ladeMitarbeiterDetails();
  },
  methods: {
    ladeMitarbeiterDetails() {
      fetch(`https://168.119.224.190:444/api/mitarbeiter/zeigeeinenmitarbeiter/${this.mitarbeiter.mitarbeiternummer}`)
        .then(response => response.json())
        .then(data => {
          this.mitarbeiter = data.data[0];
        })
        .catch(error => console.error('Fehler:', error));
    },
    aktualisiereMitarbeiter() {
      if (this.wochenstundenWarnung) {
        alert("Bitte korrigieren Sie die Fehler im Formular.");
        return; 
      }
      
      const datenZumSenden = {
        name: this.mitarbeiter.name,
        pos: this.mitarbeiter.stelle,
        gehalt: parseFloat(this.mitarbeiter.gehalt),
        wochh: parseInt(this.mitarbeiter.wochenstunden, 10),
      };

      fetch(`https://168.119.224.190:444/api/mitarbeiter/bearbeiten/${this.mitarbeiter.mitarbeiternummer}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datenZumSenden),
      })
      .then(() => {
        this.$router.push({ name: 'EmployeeDetail', params: { manr: this.mitarbeiter.mitarbeiternummer } });
      })
      .catch((error) => console.error('Fehler:', error));
    },
    validiereWochenstunden() {
      if (this.mitarbeiter.wochenstunden > 50) {
        this.wochenstundenWarnung = 'Die Zahl darf nicht über 50 sein.';
      } else {
        this.wochenstundenWarnung = ''; 
      }
    },
    abbruch() {
      this.$router.go(-1);
    },
  },
};

</script>

<style scoped>

h1 {
  text-align: center;
  margin-bottom: 2rem;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.kunden-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 500px; 
}

.form-field {
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.1rem;
}

input[type="text"], input[type="number"], input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.aktion-btn {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.aktion-btn:hover {
  background-color: #000000;
  color: #fff;
}

.abbruch {
  background-color: #000000;
  color: #fff;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.aktion-btn[type="submit"] {
  background-color: rgb(0, 0, 0); 
  color: white; 
}


.aktion-btn[type="submit"]:hover {
  background-color: rgb(0, 0, 0);
}

.warnung {
  color: red;
}

@media screen and (max-width: 768px) {
  .kunden-form {
    width: 90%;
  }
}
</style>

