<template>
    <div id="app">
      
      
      <AdministrationOverview/>
  
    </div>
  </template>
  
  <script>
 
  import AdministrationOverview from '../components/AdministrationOverview.vue';

  
  
  
  
  export default {
    name: 'App',
    components: {
      
      AdministrationOverview
  },
  mounted() {
    window.scrollTo(0, 0);
  }
  }
  
  </script>
  
  <style>
  
  </style>