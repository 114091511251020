<template>
    <div>
      <div v-for="mitarbeiter in mitarbeiterListe" :key="mitarbeiter.mitarbeiternummer" @click="zeigeMitarbeiterDetails(mitarbeiter.mitarbeiternummer)" class="mitarbeiter-kachel">
        <h3>{{ mitarbeiter.name }}</h3>
        <p>Mitarbeiternummer: {{ mitarbeiter.mitarbeiternummer }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        mitarbeiterListe: [],
      };
    },
    mounted() {
      this.ladeAlleMitarbeiter();
    },
    methods: {
      ladeAlleMitarbeiter() {
        fetch('https://168.119.224.190:444/api/mitarbeiter/zeigeallemitarbeiter')
          .then((response) => response.json())
          .then((data) => {
            this.mitarbeiterListe = data.data;
          })
          .catch((error) => console.error('Fehler:', error));
      },
      zeigeMitarbeiterDetails(mitarbeiternummer) {
        this.$router.push({ name: 'EmployeeDetail', params: { manr: mitarbeiternummer } });
      },
    },
  };
  </script>
  
  <style scoped>
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem; 
  }
  
  .mitarbeiter-kachel {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1.2rem; 
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: transparent;
    color: #1d1d1d;
    font-family: 'Open Sans', sans-serif !important;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; 
    width: 95%; 
    max-width: 600px; 
  }
  
  .mitarbeiter-kachel:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.2); 
  }
  
  .mitarbeiter-kachel h3 {
    font-family: 'Open Sans', sans-serif !important;
    color: #222222;
    margin: 0 0 1rem 0; 
  }
  
  .mitarbeiter-kachel p {
    font-family: 'Open Sans', sans-serif !important;
    color: #1d1d1d;
    margin: 0; 
  }
  
  @media screen and (max-width: 800px) {
    .mitarbeiter-kachel {
      padding: 1rem; 
      width: 95%; 
    }
  }
  </style>
  
  