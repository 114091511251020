<template>
    <div>
      <div v-if="mitarbeiter">
        <h2>Mitarbeiterdetails</h2>
        <p>Name: {{ mitarbeiter.name }}</p>
        <p>Mitarbeiternummer: {{ mitarbeiter.mitarbeiternummer }}</p>
        <p>Stelle: {{ mitarbeiter.stelle }}</p>
        <p>Gehalt: {{ mitarbeiter.gehalt }}</p>
        <p>Wochenstunden: {{ mitarbeiter.wochenstunden }}</p>
        <div class="button-container">
        <button @click="zurueck">Zurück</button>
        
        <button @click="bearbeiten">Bearbeiten</button>
        </div>
      </div>
      <div v-if="varianten.length">
        <h3>Zugeordnete Produkte</h3>
        <div v-for="variante in varianten" :key="variante.varianteid" class="variante-kachel">
          <p>Produkt: {{ variante.produktnummer }}</p>
          <p>Variante: {{ variante.variantennummer }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        mitarbeiter: null,
        varianten: [],
      };
    },
    mounted() {
      this.ladeMitarbeiterDetails();
    },
    methods: {
      ladeMitarbeiterDetails() {
        const manr = this.$route.params.manr;
        fetch(`https://168.119.224.190:444/api/mitarbeiter/zeigeeinenmitarbeiter/${manr}`)
          .then((response) => response.json())
          .then((data) => {
            this.mitarbeiter = data.data[0];
            return fetch(`https://168.119.224.190:444/api/mitarbeiter/zeigevariantenvonmitarbeiter/${manr}`);
          })
          .then((response) => response.json())
          .then((data) => {
            this.varianten = data.data;
          })
          .catch((error) => console.error('Fehler:', error));
      },
      zurueck() {
        this.$router.push({ name: 'EmployeeOverview' });
      },
      
      bearbeiten() {
        this.$router.push({ name: 'EmployeeEdit', params: { manr: this.mitarbeiter.mitarbeiternummer } });
      },
    },
  };
  </script>
  
  <style scoped>
div {
  padding: 0 0.625rem !important;
  flex: 0 0 auto;
  width: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% auto;
  gap: 1rem;
}

h2, h3 {
  font-family: 'Open Sans', sans-serif !important;
  color: #1d1d1d;
}

p {
  font-family: 'Open Sans', sans-serif !important;
  color: #1d1d1d;
  padding: 0.5rem 0;
}

.variante-kachel {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 10px;
  background-color: transparent;
  color: #1d1d1d;
  font-family: 'Open Sans', sans-serif !important;
  width: 95%;
}

.button-container {
  display: flex;
  justify-content: space-around;
  gap: 10px; 
  width: 100%; 
}

button {
  background-color: #222222;
  color: #fffdfd;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 1rem !important;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

button:hover {
  background-color: #0056b3;
}

@media screen and (max-width: 800px) {
  div {
    width: 100%;
  }
}
</style>
