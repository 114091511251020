<template>
  <h1>Produkt bearbeiten</h1>
  <div class="form-container">
    <form @submit.prevent="aktualisiereProdukt" class="kunden-form">
      <div class="form-field">
        <label for="bezeichnung">Bezeichnung:</label>
        <input id="bezeichnung" v-model="produkt.bezeichnung" type="text">
      </div>

      <div class="form-field">
        <label for="preiseinheit">Preiseinheit:</label>
        <input id="preiseinheit" v-model="produkt.preiseinheit" type="text">
      </div>

      <div class="button-container">        
        <button @click="abbruch" type="button" class="aktion-btn abbruch">Abbruch</button>
        <button type="submit" class="aktion-btn">Speichern</button>
      </div>
    </form>
  </div>
</template>
  
  <script>
  export default {
  data() {
    return {
      produkt: {
        produktnummer: this.$route.params.pnr,
        bezeichnung: '',
        preiseinheit: '',
      },
    };
  },
  mounted() {
    this.ladeProduktDetails();
  },
  methods: {
    ladeProduktDetails() {
      const pnr = this.$route.params.pnr;
      fetch(`https://168.119.224.190:444/api/produkt/zeigeprodukt/${pnr}`)
        .then(response => response.json())
        .then(data => {
          this.produkt = data.data[0];
        })
        .catch(error => console.error('Fehler:', error));
    },
    validateProduct() {
      if (!this.produkt.bezeichnung.trim()) {
        alert("Die Bezeichnung darf nicht leer sein.");
        return false;
      }
      if (!this.produkt.preiseinheit.trim()) {
        alert("Die Preiseinheit darf nicht leer sein.");
        return false;
      }
      
      return true;
    },
    aktualisiereProdukt() {
      if (!this.validateProduct()) {
        return;
      }

      const datenZumSenden = {
        bez: this.produkt.bezeichnung,
        wkz: this.produkt.preiseinheit,
      };

      fetch(`https://168.119.224.190:444/api/produkt/produktbearbeiten/${this.produkt.produktnummer}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(datenZumSenden),
      })
      .then(() => {
        this.$router.push({ name: 'ProductDetails', params: { pnr: this.produkt.produktnummer } });
      })
      .catch((error) => console.error('Fehler:', error));
    },

    abbruch() {
      this.$router.go(-1);
    },
  },
};

  </script>

<style scoped>
h1 {
  text-align: center;
  margin-bottom: 2rem;
}
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.kunden-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 500px; 
}

.form-field {
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-size: 1.1rem;
}

input[type="text"], input[type="number"], input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.aktion-btn {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.aktion-btn:hover, .aktion-btn:focus {
  background-color: #000000;
  color: #fff;
}

.abbruch {
  background-color: #000000;
  color: #fff;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  .kunden-form {
    width: 90%;
  }
}
</style>

  