<template>
  <div class="order-form">
    <h2>Bestellung anlegen</h2>
    <form @submit.prevent="submitOrder" class="form-container">
      
      <div class="form-group">
        <label for="customerNumber">Kundennummer:</label>
        <input type="text" id="customerNumber" v-model="order.customerNumber" required>
      </div>
      <div class="form-group">
        <label for="orderDate">Bestelldatum:</label>
        <input type="date" id="orderDate" v-model="order.orderDate" required>
      </div>
      <div class="form-group">
        <label for="desiredDeliveryDate">Wunschlieferdatum:</label>
        <input type="date" id="desiredDeliveryDate" v-model="order.desiredDeliveryDate" required>
      </div>
      <div class="form-group">
        <label for="remarks">Anmerkungen:</label>
        <textarea id="remarks" v-model="order.remarks"></textarea>
      </div>
      <div class="form-group">
        <label for="quantity">Menge:</label>
        <input type="number" id="quantity" v-model="order.quantity" min="1" required>
      </div>

      <div class="products-grid">
        <div 
          class="product-card" 
          v-for="product in products" 
          :key="product.produktnummer" 
          @click="selectProduct(product.produktnummer)"
        >
          <h3>{{ product.bezeichnung }}</h3>
          <ul v-if="selectedProduct === product.produktnummer">
            <li v-for="variant in variants" :key="variant.variantennummer">
              <input type="radio" :id="variant.variantennummer" :value="variant.variantennummer" v-model="selectedVariant">
              <label :for="variant.variantennummer">{{ variant.farbe }}, {{ variant.speicher }}, {{ variant.groeße }} - {{ variant.ezpreis }} EUR</label>
            </li>
          </ul>
          <button v-if="orderNumber && selectedProduct === product.produktnummer && selectedVariant" @click.prevent="addPosition(product, selectedVariant, order.quantity)" class="button add-to-order-button">Zur Bestellung hinzufügen</button>
        </div>
      </div>
      <div v-if="!orderNumber">
        <div class="button-wrapper">
        <button type="submit" :disabled="!selectedVariant" class="button submit-order-button">Bestellung anlegen</button>
        </div>

      </div>
    </form>

    <div v-if="orderNumber" class="additional-positions">
      <h3>Zusätzliche Positionen für Bestellung #{{ orderNumber }}</h3>
      <ul>
        <li v-for="(position, index) in addedPositions" :key="index">
          {{ position.product.bezeichnung }} - {{ position.variant }} - Menge: {{ position.quantity }}
          <button @click="removePosition(index)" class="button remove-position-button">Entfernen</button>
        </li>
      </ul>
    </div>

    <div v-if="orderNumber" class="position-buttons">
      <button @click="submitAdditionalPositions" class="button add-more-button">Weitere Positionen hinzufügen & abschließen</button>
      <button @click="redirectToHome" class="button complete-order-button">Ohne Positionen weiter</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      order: {
        customerNumber: '',
        orderDate: this.getCurrentDate(),
        desiredDeliveryDate: '',
        remarks: '',
        quantity: 1,
      },
      products: [],
      variants: [],
      selectedProduct: null,
      selectedVariant: null,
      addedPositions: [],
      orderNumber: null,
    };
  },
  methods: {
    
    getCurrentDate() {
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1;
      let dd = today.getDate();
      mm = mm < 10 ? `0${mm}` : mm;
      dd = dd < 10 ? `0${dd}` : dd;
      return `${yyyy}-${mm}-${dd}`;
    },
    async fetchProducts() {
      try {
        const response = await fetch('https://168.119.224.190:444/api/produkt/zeigealleprodukte');
        const data = await response.json();
        this.products = data.data;
      } catch (error) {
        console.error('Fehler beim Laden der Produkte:', error);
      }
    },
    async fetchVariants() {
      if (!this.selectedProduct) return;
      try {
        const response = await fetch(`https://168.119.224.190:444/api/produkt/zeigevariantenzuprodukt/${this.selectedProduct}`);
        const data = await response.json();
        this.variants = data.data;
      } catch (error) {
        console.error('Fehler beim Laden der Varianten:', error);
      }
    },
    selectProduct(productId) {
      this.selectedProduct = productId;
      this.selectedVariant = null;
      this.fetchVariants();
    },
    addPosition(product, variant, quantity) {
      this.addedPositions.push({ product, variant, quantity });
    },
    removePosition(index) {
      this.addedPositions.splice(index, 1);
    },
    async submitOrder() {
      if (!this.validateOrder()) return
  try {
    const response = await fetch(`https://168.119.224.190:444/api/bestellung/bestellunganlegen/${this.selectedProduct}/${this.selectedVariant}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        kdnr: this.order.customerNumber,
        bdat: this.order.orderDate,
        wldat: this.order.desiredDeliveryDate,
        status: false,
        sobem: this.order.remarks,
        menge: this.order.quantity,
      }),
    });

    if (response.ok) {
      const responseData = await response.json();
      this.orderNumber = responseData.bestellnummer; 
      alert(`Bestellung erfolgreich angelegt! Bestellnummer: ${this.orderNumber}`);
      console.log(responseData); 

      
      this.selectedProduct = null;
      this.selectedVariant = null;
      this.order = { ...this.order, quantity: 1 }; 
      
    } else {
      throw new Error('Netzwerkantwort war nicht ok.');
    }
  } catch (error) {
    console.error('Fehler beim Anlegen der Bestellung:', error);
    alert('Ein Fehler ist aufgetreten: ' + error.message); 
  }
},

validateOrder() {
  
 
  
  
  if (this.order.quantity < 1) {
    alert("Die Menge muss mindestens 1 sein.");
    return false;
  }
  
 
  const orderDate = new Date(this.order.orderDate);
  const desiredDeliveryDate = new Date(this.order.desiredDeliveryDate);
  if (orderDate >= desiredDeliveryDate) {
    alert("Das Wunschlieferdatum muss nach dem Bestelldatum liegen.");
    return false;
  }
  
  
  if (!this.order.desiredDeliveryDate) {
    alert("Bitte geben Sie ein Wunschlieferdatum ein.");
    return false;
  }

 
      if (this.order.quantity < 1) {
        alert("Die Menge muss mindestens 1 sein.");
        return false;
      }
  
  
  return true;
},

    async submitAdditionalPositions() {
      if (!this.validateOrder()) return
  for (const position of this.addedPositions) {
    try {
      const response = await fetch('https://168.119.224.190:444/api/bestellung/positionanlegen', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bnr: this.orderNumber,
          pnr: position.product.produktnummer,
          vnr: position.variant,
          menge: position.quantity,
        }),
      });

      if (!response.ok) {
        throw new Error('Netzwerkantwort war nicht ok.');
      }

      const responseData = await response.json(); 
      console.log(responseData.message); 

    } catch (error) {
      console.error('Fehler beim Anlegen der Bestellposition:', error);
      
    }
    this.redirectToHome();
  }

  this.addedPositions = []; 
  alert('Alle zusätzlichen Positionen erfolgreich hinzugefügt.');
},

redirectToHome() {
    this.$router.push('/'); 
  },
  },
  mounted() {
    this.fetchProducts();
  }
}
</script>

<style scoped>
.order-form {
  padding: 0 0.625rem !important;
  flex: 0 0 auto;
  width: 95%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2% auto 3%;
  gap: 1rem;
}

.form-container, .additional-positions, .position-buttons {
  width: 100%;
}

.form-group {
  margin-top: 1rem !important;
  position: relative !important;
  width: 100%;
}

.form-group label {
  font-family: 'Open Sans', sans-serif !important;
  color: #1d1d1d;
  padding: 0.5rem 0;
}

.form-group input, .form-group textarea {
  font-family: 'Open Sans', sans-serif !important;
  color: #1d1d1d;
  padding: 0.5rem 1rem;
  border-color: #000;
  border-width: 1.5px;
  width: 100%;
  border-radius: 50rem !important;
}

.form-group input:focus, .form-group textarea:focus {
  outline: none !important;
  border-color: #a0a0a0;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.form-group input::placeholder, .form-group textarea::placeholder {
  color: #000000;
}

.products-grid, .additional-positions, .position-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.product-card, .additional-positions {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: transparent;
  color: #1d1d1d;
  font-family: 'Open Sans', sans-serif !important;
  cursor: pointer;
  width: 100%;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.product-card ul, .additional-positions ul {
  list-style-type: none;
  padding-left: 0;
}

.product-card li, .additional-positions li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 10px; 
}

.button {
  background-color: #222222;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 0 0.5rem;
}

.position-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.button:hover {
  background-color: #000000;
}

@media screen and (max-width: 800px) {
  .form-group {
    width: 100%;
  }
}

</style>





