<template>
  <div>
    <div v-for="zuordnung in zuordnungen" :key="zuordnung.mitarbeiternummer" class="zuordnung">
      <h3>Mitarbeiternummer: {{ zuordnung.mitarbeiternummer }}</h3>
      <p>Produktnummer: {{ zuordnung.produktnummer }}</p>
      <p>Variantennummer: {{ zuordnung.variantennummer }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      zuordnungen: [],
    };
  },
  mounted() {
    this.ladeZuordnungen();
  },
  methods: {
    ladeZuordnungen() {
      fetch('https://168.119.224.190:444/api/mitarbeiter/zeigeallemitarbeiterzuordnungen')
        .then(response => response.json())
        .then(data => {
          this.zuordnungen = data.data;
        })
        .catch(error => console.error('Fehler:', error));
    },
  },
};
</script>

<style scoped>
div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.zuordnung {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1.2rem;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: transparent;
  color: #1d1d1d;
  font-family: 'Open Sans', sans-serif !important;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 95%;
  max-width: 600px;
}

.zuordnung:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.zuordnung h3, .zuordnung p {
  font-family: 'Open Sans', sans-serif !important;
  color: #222222;
  margin: 0;
  margin-bottom: 0.5rem; 
}

@media screen and (max-width: 800px) {
  .zuordnung {
    padding: 1rem;
    width: 95%;
    max-width: none; 
  }
}
</style>
