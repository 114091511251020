<template>
    <div>
      <div v-for="variante in varianten" :key="variante.produktnummer + '-' + variante.variantennummer" @click="zeigeVarianteDetails(variante.produktnummer, variante.variantennummer)" class="variante">
        <h3>{{ variante.produktnummer }} - {{ variante.variantennummer }}</h3>
        <p>Farbe: {{ variante.farbe }}</p>
        <p>Speicher: {{ variante.speicher }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        varianten: [],
      };
    },
    mounted() {
      this.ladeAlleVarianten();
    },
    methods: {
      ladeAlleVarianten() {
        fetch('https://168.119.224.190:444/api/variante/zeigeallevarianten')
          .then((response) => response.json())
          .then((data) => {
            this.varianten = data.data;
          })
          .catch((error) => console.error('Fehler:', error));
      },
      zeigeVarianteDetails(pnr, vnr) {
        this.$router.push({ name: 'VariantDetails', params: { pnr, vnr } });
      },
    },
  };
  </script>
  
  <style scoped>
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  }
  
  .variante {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1.2rem;
    transition: transform 0.2s, box-shadow 0.2s;
    background-color: transparent;
    color: #1d1d1d;
    font-family: 'Open Sans', sans-serif !important;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 95%;
    max-width: 600px;
  }
  
  .variante:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  }
  
  .variante h3 {
    font-family: 'Open Sans', sans-serif !important;
    color: #222222;
    margin: 0 0 1rem 0;
  }
  
  .variante p {
    font-family: 'Open Sans', sans-serif !important;
    color: #1d1d1d;
    margin: 0;
    margin-bottom: 0.5rem; 
  }
  
  @media screen and (max-width: 800px) {
    .variante {
      padding: 1rem;
      width: 95%;
    }
  }
  </style>
  
  