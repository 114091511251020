import { createRouter, createWebHistory } from 'vue-router';

import Impressum from '../pages/Impressum.vue';
import Order from '../pages/Order.vue';
import Product from '../pages/Product.vue';
import FAQ from '../pages/FAQ.vue';
import Customer from '../pages/Customer.vue';
import Home from '../pages/Home.vue'
import PositionManagement from '../pages/PositionManagement.vue';
import Administration from '../pages/Administration.vue';
import CustomerEdit from '../pages/CustomerEdit.vue';
import CustomerOverview from '../pages/CustomerOverview.vue';
import CustomerDetail from '../pages/CustomerDetail.vue';
import VariantCreatePage from '../pages/VariantCreatePage.vue';
import ProductCreatePage from '../pages/ProductCreatePage.vue';
import EmployeeCreatePage from '../pages/EmployeeCreatePage.vue';
import EmployeeAssignmentPage from '../pages/EmployeeAssignmentPage.vue';
import VariantEditPage from '../pages/VariantEditPage.vue';
import VariantOverviewPage from '../pages/VariantOverviewPage.vue';
import VariantDetailPage from '../pages/VariantDetailPage.vue';
import ProductOverviewPage from '../pages/ProductOverviewPage.vue';
import ProductDetailPage from '../pages/ProductDetailPage.vue';
import ProductEditPage from '../pages/ProductEditPage.vue';
import EmployeeEditPage from '../pages/EmployeeEditPage.vue';
import EmployeeOverviewPage from '../pages/EmployeeOverviewPage.vue';
import EmployeeDetailPage from '../pages/EmployeeDetailPage.vue';
import AssignmentOverviewPage from '../pages/AssignmentOverviewPage.vue';



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  
  {
    path: '/Impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/Order',
    name: 'Order',
    component: Order
  },
  {
    path: '/Management',
    name: 'Management',
    component: PositionManagement
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/Customer',
    name: 'Customer',
    component: Customer
  },
  {
    path: '/Administration',
    name: 'Administration',
    component: Administration
  },
  {
    path: '/Administration/Customer/Create',
    name: 'CustomerCreate',
    component: Customer
  },
  {
    path: '/Administration/Customer/:kdnr/Edit',
    name: 'CustomerEdit',
    component: CustomerEdit
  },
  {
    path: '/Administration/Customer/all',
    name: 'CustomerAll',
    component: CustomerOverview
  },
  {
    path: '/Administration/Customer/:kdnr',
    name: 'CustomerDetail',
    component: CustomerDetail
  },
  {
    path: '/Administration/Variant/all',
    name: 'VariantOverview',
    component: VariantOverviewPage
  },
  {
    path: '/Administration/Variant/:pnr/:vnr',
    name: 'VariantDetails',
    component: VariantDetailPage
  },
  {
    path: '/Administration/Variant/:pnr/:vnr/Edit',
    name: 'VariantEdit',
    component: VariantEditPage
  },
  {
    path: '/Administration/Variant/Create',
    name: 'VariantCreate',
    component: VariantCreatePage
  },
  {
    path: '/Administration/Product/Create',
    name: 'ProductCreate',
    component: ProductCreatePage
  },
  {
    path: '/Administration/Product/all',
    name: 'ProductOverview',
    component: ProductOverviewPage
  },
  {
    path: '/Administration/Product/:pnr',
    name: 'ProductDetails',
    component: ProductDetailPage
  },
  {
    path: '/Administration/Product/:pnr/Edit',
    name: 'ProductEdit',
    component: ProductEditPage
  },
  {
    path: '/Administration/Employee/Create',
    name: 'EmployeeCreate',
    component: EmployeeCreatePage
  },
  {
    path: '/Administration/Employee/all',
    name: 'EmployeeOverview',
    component: EmployeeOverviewPage
  },
  {
    path: '/Administration/Employee/:manr',
    name: 'EmployeeDetail',
    component: EmployeeDetailPage
  },
  {
    path: '/Administration/Employee/:manr/Edit',
    name: 'EmployeeEdit',
    component: EmployeeEditPage
  },
  {
    path: '/Administration/Employee/Assignment',
    name: 'EmployeeAssignment',
    component: EmployeeAssignmentPage
  },
  {
    path: '/Administration/Employee/Assignment/all',
    name: 'EmployeeAssignmentOverview',
    component: AssignmentOverviewPage
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
