<template>
    <div id="app">
      <router-view/>
      
      <assignmentOverview/>
  
    </div>
  </template>
  
  <script>

  import assignmentOverview from '../components/assignmentOverview.vue';

  
  
  
  
  export default {
    name: 'App',
    components: {
      
      assignmentOverview
  },
  mounted() {
    window.scrollTo(0, 0);
  }
  }
  
  </script>
  
  <style>
  
  </style>